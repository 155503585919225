export default {
  content: null,
  options: {},
  title: '',

  text(title, text, options = {}) {
    this.open(title, { type: 'text', text }, options)
  },

  remote(title, url, options = {}) {
    this.open(
      title,
      {
        type: 'url',
        url,
        method: options.method || 'POST',
        values: options.values,
      },
      options,
    )
  },

  inline(title, selector, options = {}) {
    this.open(title, { type: 'selector', selector }, options)
  },

  confirm() {
    const confirmed = this.options.confirmed
    if (typeof this.options.confirm == 'function') this.options.confirm()
    this.close()
    if (typeof confirmed == 'function') setTimeout(() => confirmed(), 5)
  },

  cancel() {
    if (typeof this.options.cancel == 'function') this.options.cancel()
    this.close()
  },

  open(title, content, options = {}) {
    this.content = content
    this.options = options
    this.title = title
  },

  close() {
    this.content = null
    this.options = {}
    this.title = ''
  },
}
