import { parseRawHtmlDoc } from '~/utils/dom-parser'

export default (prefix, subject, data, usePrefix = true) => ({
  data: JSON.parse(data),
  prefix: usePrefix ? `${subject}_${prefix}` : subject,
  query: '',

  useAccountData() {
    this.prefillData(this.data)
  },

  clearData() {
    this.prefillData(
      Object.fromEntries(
        Object.entries(this.data).map(([key, _]) => [key, '']),
      ),
    )
  },

  prefillData(data) {
    var input
    Object.entries(data).forEach(([key, value]) => {
      if (!(input = this.findInput(key))) return

      input.value = value
      input.dispatchEvent(new Event('change', { bubbles: true }))
    })
  },

  loadRelations(title, path, group = 'name') {
    this.$store.modal.remote(title, path, {
      method: 'GET',
      values: { scope: prefix, group: group },
      cancel: true,
    })
  },

  findInput(key) {
    return this.$root.querySelector(`#${this.prefix}_${key}`)
  },
})
