import { flashMessage } from '~/utils/flash'
import { getCSRFToken } from '~/utils/security'

export default (path, params, i18n) => ({
  originalQuantity: params.amount,

  addToCart() {
    htmx.ajax('POST', path, {
      values: params,
      headers: { 'X-CSRF-Token': getCSRFToken() },
      swap: 'none',
      handler: (_, { xhr: { responseText } }) => {
        if (!parseInt(params.amount)) return this.promptForQuantity()

        this.handleResponse(responseText)
      },
    })
  },

  handleResponse(responseText) {
    const { type, message, data } = JSON.parse(responseText)
    flashMessage(message, type)

    if (data.number_of_jobs != null) this.numberOfJobs = data.number_of_jobs
    if (data.problematic_extra_ids != null)
      this.problematicOptions = data.problematic_extra_ids
    if (data.problematic_option_groups != null)
      this.highlightProblematicOptionGroups(data.problematic_option_groups)

    params.amount = this.originalQuantity
  },

  noQuantity() {
    return !/\d/.test(this.quantity)
  },

  promptForQuantity() {
    this.$store.modal.inline(i18n.modalTitle, '#quantity_modal_template', {
      cancel: true,
      confirm: () => {
        const input = document.getElementById('quantity_modal_input')

        if (!input) return

        params.amount = input.value
        this.addToCart()
      },
      confirmLabel: i18n.confirmLabel,
    })
  },

  highlightProblematicOptionGroups(groups) {
    groups.forEach((group) => {
      const element = document.querySelector(`[data-option-group='${group}']`)
      if (element) element.classList.add('with-error')
    })
  },
})
